export const EXPERIENCES = [
  {
    organization: "Wells Fargo",
    logo: "wellsfargo.png",
    duration: "Jul '22 - Present",
    role: "Software Engineer",
    workedIn: "React, SpringBoot, Microservices, Microfrontends",
  },
  {
    organization: "Totallr Technologies",
    logo: "totallr.png",
    duration: "Apr '21 - Jun '21",
    role: "Full Stack Web Developer",
    workedIn: "Angular, Spring Boot, AWS S3",
  },
  {
    organization: "CEG Tech Forum",
    logo: "ctf.png",
    duration: "Dec '21 - Jun '22",
    role: "Student Director of Technical Operations",
    workedIn: "React, ExpressJS, AWS",
  },
  {
    organization: "Computer Science and Engineering Association, CEG",
    logo: "csea.png",
    duration: "Jan '21 - Mar '21",
    role: "Frontend Developer",
    workedIn: "React",
  },
  {
    organization: "Computer Society of Anna University",
    logo: "csau.png",
    duration: "Jun '21 - Jun '22",
    role: "Student Director",
    workedIn: "Angular",
  },
];
